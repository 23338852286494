.loginContent {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow-y: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.screen-1 {
    padding: 2em;
    display: flex;
    flex-direction: column;
    border-radius: 30px;
    box-shadow: 0 0 2em #e6e9f9;
    gap: 2em;
}

.logo img {
    width: 300px;
    margin-bottom: 50px;
}

.screen-1 .email {
    background: white;
    box-shadow: 0 0 2em #e6e9f9;
    padding: 1em;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    border-radius: 20px;
    color: #4d4d4d;
    margin-top: -3em;
}

.screen-1 .email input {
    outline: none;
    border: none;
}

.screen-1 .email input::-moz-placeholder {
    color: black;
    font-size: 0.9em;
}

.screen-1 .email input:-ms-input-placeholder {
    color: black;
    font-size: 0.9em;
}

.screen-1 .email input::placeholder {
    color: black;
    font-size: 0.9em;
}

.screen-1 .email ion-icon {
    color: #4d4d4d;
    margin-bottom: -0.2em;
}

.screen-1 .password {
    background: white;
    box-shadow: 0 0 2em #e6e9f9;
    padding: 1em;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    border-radius: 20px;
    color: #4d4d4d;
}

.screen-1 .password input {
    outline: none;
    border: none;
}

.screen-1 .password input::-moz-placeholder {
    color: black;
    font-size: 0.9em;
}

.screen-1 .password input:-ms-input-placeholder {
    color: black;
    font-size: 0.9em;
}

.screen-1 .password input::placeholder {
    color: black;
    font-size: 0.9em;
}

.screen-1 .password ion-icon {
    color: #4d4d4d;
    margin-bottom: -0.2em;
}

.screen-1 .password .show-hide {
    margin-right: -5em;
}

.screen-1 .login {
    padding: 1em;
    background: #3e4684;
    color: white;
    border: none;
    border-radius: 30px;
    font-weight: 600;
}

.screen-1 .footer {
    display: flex;
    font-size: 0.7em;
    color: #5e5e5e;
    gap: 14em;
    padding-bottom: 10em;
}

.screen-1 .footer span {
    cursor: pointer;
}

button {
    cursor: pointer;
}
