/*
essential styles:
these make the slideshow work
*/
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css");


#slides {
    position: relative;
    height: 600px;
    padding: 0px;
    margin: 0px;
    list-style-type: none;

}

.slide {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
    -webkit-transition: opacity 1s;
    -moz-transition: opacity 1s;
    -o-transition: opacity 1s;
    transition: opacity 1s;
}

.showing {
    opacity: 1;
    z-index: 2;
}



/*
non-essential styles:
just for appearance; change whatever you want
*/

.slide {
    font-size: 40px;
    padding: 40px;
    box-sizing: border-box;
    background: #333;
    color: #fff;
}

.slide:nth-of-type(1) {
    background: red;
}

.slide:nth-of-type(2) {
    background: orange;
}

.slide:nth-of-type(3) {
    background: green;
}

.slide:nth-of-type(4) {
    background: blue;
}

.slide:nth-of-type(5) {
    background: purple;
}

.main-slider {
    position: relative;
}

.slider-buttons {
    position: absolute;
    bottom: 10px;
    z-index: 9999;
    left: 50%;
    transform: translate(-50%, -30%);
}

.slider-buttons button {
    margin-left: 5px;
}


.header {
    text-align: center;
}

.Source {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.table-heading {
    font-size: 1rem;
}

.UTC {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.col-1 {
    border: 1px solid black;
}


table,
th {
    border: 1px solid black;
}

.map {
    width: 100%;
    height: 77vh;

}

.line {

    background-color: #DCDCDC;
    height: 1px;
    width: 106%;
}

.line2 {
    background-color: blue;
    height: 2px;
    width: 200px;
}

.end {
    font-size: 12px;
}

.black {
    color: black;
    font-weight: bold;
}

.blue {
    color: blue;
    font-weight: bold;
}

.red {
    color: red;
    font-weight: bold;
}

.grey {
    color: grey;
}


.lg-container {
    width: 95%;
    margin-left: auto;
    margin-right: auto;

}

.table-row {
    border: 1px solid black;
}


.t-head {
    border: 1px solid black;
    padding: 5px;

}

.t-blank {
    width: 5%;
    border: 1px solid black;
}

.t-1 {
    width: 5%;
    border: 1px solid black;
}

.t-3 {
    width: 5%;
    background-color: aquamarine;
    border: 1px solid black;
}

.t-4 {
    width: 20%;
    background-color: aquamarine;
    border: 1px solid black;
}

.t-5 {
    background: antiquewhite;
    border: 1px solid black;
}

.arrow {
    border: solid blue;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
}

.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}


.bi-circle {
    color: blue;
    border-width: 0 3px 3px 0;
    margin-bottom: 4px;


}

.table-custom tr th{
    font-size: 10px;
}
.table-custom tr td{
    font-size: 12px;
}

/* //////media////// */
/* 
@media only screen and (max-width: 600px) {
    .map {
        width: 100%;

    }
    
} */

/* @media only screen and (max-width: 1920px) {
    .map {
        width: 100%;

    }
    .map_container {
        width: 100%; 
        height:650px;
    }
}
@media only screen and (max-width: 1536px) {
    .map {
        width: 100%;

    }
    .map_container {
        width: 100%; 
        height:355px;
    }
}

@media only screen and (max-width: 425px) {
    .map {
        width: 100%;

    }
} */

/* (1366x768) WXGA Display */
@media  screen and (max-width: 1366px) {
    .map {
        width: 100%;

    }
    .map_container {
        width: 100%; 
        height:500px;
    }
}
 
/* (1280x1024) SXGA Display */
@media  screen and (max-width: 1280px) {
    .map {
        width: 100%;

    }
    .map_container {
        width: 100%; 
        height:750px;
    }
}
 
/* (1440x900) WXGA+ Display */
@media  screen and (max-width: 1440px) {
    .map {
        width: 100%;

    }
    .map_container {
        width: 100%; 
        height:580px;
    }
}
 
/* (1680x1050) WSXGA+ Display */
@media  screen and (max-width: 1680px) {
    .map {
        width: 100%;

    }
    .map_container {
        width: 100%; 
        height:750px;
    }
}
 
/* (1920x1080) Full HD Display */
@media  screen and (max-width: 1920px) {
    .map {
        width: 100%;

    }
    .map_container {
        width: 100%; 
        height:700px;
    }
}
 
/* (1600x900) HD+ Display */
@media  screen and (max-width: 1600px) {
    .map {
        width: 100%;

    }
    .map_container {
        width: 100%; 
        height:600px;
    }
}
@media only screen and (min-width: 2736px) {
    .map {
        width: 100%;

    }
    .map_container {
        width: 100%; 
        height:1300px;
    }
}

.btn-circle.btn-xl {
    width: 50px;
    height: 50px;
    padding: 5px 7px;
    border-radius: 60px;
    font-size: 15px;
    text-align: center;
}

.blink {
    color: rgb(169, 166, 166);
    margin-right: 0.5rem;
    animation: blink 10s steps(10, start) infinite;
    -webkit-animation: blink 3s steps(10, start) infinite;
    /* border: 1px solid; */
    /* padding: 10px; */
   /* // box-shadow: 5px 10px 18px #888888; */

}
.blink {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: relative;
    animation: shadowPulse 1s infinite linear;
}

.blink::before {
    position: absolute;
    content: '';
    border-top: transparent 15px solid;
    border-bottom: transparent 15px solid;
    border-left: #fff 25px solid;
    top: 50%;
    left: 50%;
    transform: translate(-40%, -50%);
}

.blink::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: #fff solid 2px;
    border-radius: 100%;
    animation: ringPulse 1s infinite linear;
}
.header-dark{
    color: white !important;
    background-color: black !important;
}
/* 
@keyframes blink {

    0% {
        visibility: visible;
    }

    33.33% {
        visibility: hidden;
    }

    50% {
        visibility: visible;
    }

    70% {
        visibility: hidden;
    }

    100% {
        visibility: show;
    }
}

@-webkit-keyframes blink {

    0% {
        visibility: visible
    }

    33.33% {
        visibility: hidden
    }

    50% {
        visibility: visible
    }

    70% {
        visibility: hidden
    }

    100% {
        visibility: show
    }
} */
@keyframes ringPulse {
    0% {
        opacity: 0;
        transform: scale(0.95);
    }

    10% {
        opacity: 1;
    }

    80%, 100% {
        opacity: 0;
        transform: scale(2);
    }
}

@keyframes shadowPulse {
    0% {
        box-shadow: 0 0 8px 6px transparent,
                    0 0 0 0 transparent,
                    0 0 0 0 transparent;
    }

    10% {
        box-shadow: 0 0 8px 6px #e1434b,
                    0 0 12px 10px transparent,
                    0 0 12px 5px #e1434b;
    }

    80%, 100% {
        box-shadow: 0 0 8px 6px transparent,
                    0 0 0 40px transparent,
                    0 0 0 40px transparent;
    }
}
.noblink {
    margin-right: 0.5rem;
}